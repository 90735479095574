import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import Accordion from "../../Accordion";
import { Row, Col } from "react-bootstrap";
import { AsOfAgingForm } from "./AsOfAgingForm";
import ErrorHandler from "./../../core/ErrorHandler";
import { DetailsModal } from "../../grid/DetailsModal";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
// import { IsNullOrWhitespace } from "../../../helpers/StringHelpers";

// import GridExport from "../../export/GridExport";

import SharedMainGrid from "../../grid/Kendo/SharedMainGrid";

import SharedMainSelectableServerGrid from "../../grid/Kendo/SharedMainSelectableServerGrid";

import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, aggregateBy } from "@progress/kendo-data-query";
//import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ZIndexContext } from "@progress/kendo-react-common";

import {
  MoneyCell,
  AggregateMoneyFooterCell,
  //AggregateNumberFooterCell,
} from "../../grid/Kendo/CustomKendoGridCells";
import { UserContext } from "../../context/UserContext";
import {
  DefaultColumnWidth,
  DefaultDateColumnWidth,
  DefaultBooleanOrShortWidthColumnWidth,
} from "../../grid/Kendo/KendoGridAndColumnConstants";

export function AsOfAging(props) {
  const { passedProps } = props;
  const user = passedProps.user.email;
  const userConfig = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [hasTPARAccess, setTPARAcces] = useState(false);
  const [error, setError] = useState({});
  const INITIAL_DETAILS_GRID_DATA_STATE = { skip: 0, take: 50 };

  const [submittedFormData, setSubmittedFormData] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [detailError, setDetailError] = useState({});

  const [mainGridData, setMainGridData] = useState([]);
  const [mainGridColumns, setMainGridColumns] = useState([]);
  const [mainGridfilter, setMainGridFilter] = useState([]);
  const [
    mainGridMoneyColumnAggregateSettings,
    setMainGridMoneyColumnAggregateSettings,
  ] = useState([]);
  const [mainGridAggregates, setMainGridAggregates] = useState([]);

  const [detailsGridParameters, setDetailsGridParameters] = useState({});
  const [serverGridData, setServerGridData] = useState([]);

  const [detailsGridData, setDetailsGridData] = useState([]);
  // const [detailColumns, setDetailColumns] = useState([]);
  const [detailsSummaryData, setDetailsSummaryData] = useState([]);
  // const [detailSummaryColumns, setDetailsSummaryColumns] = useState([]);
  const [detailsGridTitle, setDetailsGridTitle] = useState("");
  const [detailsGridAggregates, setDetailsGridAggregates] = useState([]);
  const [detailsGridDataState, setDetailsGridDataState] = useState({});
  const [detailsGridResultState, setDetailsGridResultState] =
    useState(detailsGridData);

  const [detailsSummaryDataOrchestrator, setDetailsSummaryDataOrchestrator] =
    useState([]);

  // const [exportData, setExportData] = useState([]);
  // const [exportColumns, setExportColumns] = useState([]);

  const sendAggregatesToParent = (aggregatesFromAgingGridSkeleton) => {
    setMainGridAggregates(aggregatesFromAgingGridSkeleton.data);
    setMainGridFilter(aggregatesFromAgingGridSkeleton.filter);
  };

  const _container = React.useRef();
  const _detailsGrid = React.useRef();

  // const excelDetailsGridExport = () => {
  //   let filterAndSortSettings = {};
  //   filterAndSortSettings.filter = detailsGridDataState.filter;
  //   filterAndSortSettings.sort = detailsGridDataState.sort;

  //   let processedData = process(detailsGridData, filterAndSortSettings);
  //   _detailsExport.current.save(processedData, _detailsGrid.current.columns);
  // };

  const onDetailsGridDataStateChange = (event) => {
    setDetailsGridDataState(event.dataState);
    /*
    NOTE: This fixes a complication because the normal data is an array,
    but that "original data" becomes a property within an object
    after passing through the "process()" method
    */
    let processedData = process(detailsGridData, event.dataState);

    /*
    NOTE TODO: This is sort of okay as long as we don't have paging, but when we add paging it creates a problem
    SEE HOW WE'RE PASSING "processedData.data" and not "processedData"?
    This mostly has to do with the selection logic below needing to do a "map()" call for the Kendo selectable logic
    */
    setDetailsGridResultState(processedData);
  };

  useEffect(() => {
    if (
      userConfig.currentOrganization &&
      (userConfig.contactType == "PCT" || userConfig.contactType == "MCT")
    ) {
      getTPARAccess();
    } else {
      setTPARAcces(true);
    }
  }, []);

  function getTPARAccess() {
    let accessToken = passedProps.auth.getAccessToken();

    try {
      let response = axios
        .get("api/Aging/GetTPARAccess", {
          params: {
            rpid: userConfig.currentOrganization.value,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          setTPARAcces(response.data);
        })
        .catch(setTPARAcces(false));
    } catch (exception) {
      setTPARAcces(false);
    }
  }

  //NOTE: If the details modal grid data changes, then this is what kicks in
  useEffect(() => {
    let processedData = process(detailsGridData, detailsGridDataState);

    /*
    NOTE TODO: This is sort of okay as long as we don't have paging, but when we add paging it creates a problem
    SEE HOW WE'RE PASSING "processedData.data" and not "processedData"?
    This mostly has to do with the selection logic below needing to do a "map()" call for the Kendo selectable logic
    */
    setDetailsGridResultState(processedData);
    setDetailsGridDataState(INITIAL_DETAILS_GRID_DATA_STATE);
  }, [detailsGridData]);

  useEffect(() => {
    let aggregates = {};
    let filterSettings = {};

    filterSettings.filter = detailsGridDataState.filter;
    let filteredDetailsGridData = process(detailsGridData, filterSettings);

    if (filteredDetailsGridData.data) {
      aggregates = aggregateBy(filteredDetailsGridData.data, [
        { aggregate: "sum", field: "adjudicatedCoPay" },
        { aggregate: "sum", field: "promiseToPay" },
        { aggregate: "sum", field: "remittancePaid" },
        { aggregate: "sum", field: "outstanding" },
      ]);
    }

    // setExportData(filteredDetailsGridData.data);

    // if (_detailsGrid.current && _detailsGrid.current.columns)
    //   setExportColumns(_detailsGrid.current.columns);

    // console.log(aggregates);
    setDetailsGridAggregates(aggregates);
  }, [detailsGridResultState]);

  useEffect(() => {
    if (
      detailsSummaryDataOrchestrator &&
      detailsSummaryDataOrchestrator.PendingSummaryData
    )
      presentGridDetailDataNew();
  }, [serverGridData]);

  let MainGridMoneyCell = (props) => {
    let onCellClick = () => {
      let accessToken = passedProps.auth.getAccessToken();

      if (props.field === "TotalOutstanding") {
        setDetailsGridTitle(
          "BIN - " +
            props.dataItem["Bin"] +
            ", " +
            props.dataItem["Payer"] +
            " Total Outstanding"
        );
      } else {
        setDetailsGridTitle(
          "BIN - " +
            props.dataItem["Bin"] +
            ", " +
            props.dataItem["Payer"] +
            ", " +
            props.field +
            " Days Old"
        );
      }

      try {
        setShowModal(true);

        //while loading just set the payer info on the summary grid, the rest will be set when the data is returned and main detail grid is rendered
        let detailSummaryData = [];

        detailSummaryData.push({
          Payer: props.dataItem["Payer"],
        });

        setDetailsSummaryData(detailSummaryData);

        let detailSummaryOrchastrator = {
          Payer: props.dataItem["Payer"],
          PendingSummaryData: true,
        };
        setDetailsSummaryDataOrchestrator(detailSummaryOrchastrator);

        let parameters1 = {
          user: user,
          pbid: JSON.stringify(props.dataItem["PBID"])
            .replace("[", "")
            .replace("]", ""),
          asOfDate: submittedFormData.toDate,
          mmids: submittedFormData.mmids,
          bin: props.dataItem["Bin"],
          period: props.field,
          payer: props.dataItem["Payer"],
        };

        setDetailsGridParameters(parameters1);

        return;

        setIsLoading(true);
        let response = axios
          .get("api/AgingDetails/GetByAsOfAging", {
            params: {
              user: user,
              pbid: JSON.stringify(props.dataItem["PBID"])
                .replace("[", "")
                .replace("]", ""),
              asOfDate: submittedFormData.toDate,
              mmids: submittedFormData.mmids,
              bin: props.dataItem["Bin"],
              period: props.field,
              payer: props.dataItem["Payer"],
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then((response) => {
            onSuccess(response);
          })
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    };

    let options = {
      onClick: onCellClick,
    };

    return MoneyCell(props, options);
  };

  const AggregateMainGridMoneyFooterCell = (props) => {
    let onCellClick = () => {
      if (props.field === "TotalOutstanding") {
        setDetailsGridTitle("Total Outstanding Aggregate");
      } else {
        setDetailsGridTitle(props.field + " Days Old Aggregate");
      }
      let pushPBID = [];
      if (mainGridfilter == null || mainGridfilter == undefined) {
        for (var count = 0; count < mainGridData.length; count++) {
          pushPBID.push(mainGridData[count].PBID);
        }
      } else {
        let filterSettings = {};
        filterSettings.filter = mainGridfilter;
        var filterResult = process(mainGridData, filterSettings).data;
        for (var count = 0; count < filterResult.length; count++) {
          pushPBID.push(filterResult[count].PBID);
        }
      }
      if (pushPBID.length == 0) {
        return;
      }

      try {
        //setIsLoading(true);
        let accessToken = passedProps.auth.getAccessToken();

        let response = axios
          .get("api/AgingDetails/GetByAsOfAging", {
            params: {
              user: user,
              pbid: JSON.stringify(pushPBID).replace("[", "").replace("]", ""),
              asOfDate: submittedFormData.toDate,
              mmids: submittedFormData.mmids,
              bin: " ",
              period: props.field,
              payer: " ",
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then((response) => {
            onSuccess(response);
          })
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    };

    let options = {
      aggregateData: mainGridAggregates,
      onClick: onCellClick,
    };

    return AggregateMoneyFooterCell(props, options);
  };

  const AggregateMoneyDetailFooterCell = (props) => {
    // let options = {
    //   aggregateData: detailsGridAggregates,
    // };

    // return AggregateMoneyFooterCell(props, options);

    let displayAggregateValue = 0;

    if (serverGridData && serverGridData.fieldTotals) {
      let fieldTotals = serverGridData.fieldTotals;
      let field = props.field.toLowerCase();
      let fieldTotal = fieldTotals[field];
      if (fieldTotal) {
        displayAggregateValue = fieldTotal;
      }
    }

    return <td {...props.tdProps}>{"$" + displayAggregateValue}</td>;
  };

  function handleFormSubmit(e) {
    setIsLoading(true);
    setSubmittedFormData(e);

    let accessToken = passedProps.auth.getAccessToken();

    try {
      let response = axios
        .get(`api/Aging/GetAsOfAging`, {
          params: {
            user: user,
            asOfDate: e.toDate,
            mmids: e.mmids,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function onSuccess(response) {
    switch (response.config.url) {
      case "api/Aging/GetAsOfAging":
        presentGridData(response.data);
        break;
      // case "api/AgingDetails/GetByAsOfAging":
      //   presentGridDetailsData(response);
      //   break;
      default:
        break;
    }
    setIsLoading(false);
  }

  function onFailure(error) {
    console.log(error);
    switch (error.response.config.url) {
      case "api/Aging/GetAsOfAging":
        setError(error.response);
        setMainGridData([]);
        break;
      case "api/AgingDetails/GetByAsOfAging":
        setDetailError(error.response);
        setDetailsSummaryData([]);
        setDetailsGridData([]);
        setShowModal(true);
        break;
      default:
        setError(error.response);
        setMainGridData([]);
        break;
    }
    setIsLoading(false);
    return;
  }

  function presentGridData(data) {
    let columns = [];

    if (data.length > 0) {
      let headerProps = {
        PBID: { title: "PBID", type: "int", hidden: true },
        Payer: { title: "Payer Name", type: "text" },
        Bin: { title: "BIN", type: "text" },
        TotalOutstanding: {
          title: "Total Outstanding",
          type: "money",
          showTotal: true,
        },
      };

      Object.keys(data[0]).map((key) => {
        return columns.push({
          accessor: key,
          title: headerProps[key]
            ? headerProps[key].title
            : key.includes("+")
            ? `${key} days and over`
            : `${key} days`,
          type: headerProps[key] ? headerProps[key].type : "money",
          showTotal: headerProps[key] ? headerProps[key].showTotal : true,
          selectable: headerProps[key] ? headerProps[key].selectable : true,
          hidden: headerProps[key] ? headerProps[key].hidden : false,
        });
      });

      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given params" });
    }
    setMainGridColumns(columns);
    setMainGridData(data);

    let moneyColumns = [];
    columns.forEach((column) => {
      if (column.type === "money") {
        moneyColumns.push({ aggregate: "sum", field: column.accessor });
      }
    });

    setMainGridMoneyColumnAggregateSettings(moneyColumns);
  }

  function presentGridDetailDataNew() {
    let detailSummaryData = [];

    detailsSummaryDataOrchestrator.PendingSummaryData = false;

    setDetailsSummaryDataOrchestrator(detailsSummaryDataOrchestrator);

    detailSummaryData.push({
      Payer: detailsSummaryDataOrchestrator.Payer,
      ClaimCount: serverGridData.total,
      Adjudicated: serverGridData.fieldTotals.promisetopay,
      Paid: serverGridData.fieldTotals.remittancepaid,
      Outstanding: serverGridData.fieldTotals.outstanding,
    });

    setDetailsSummaryData(detailSummaryData);
  }

  // function presentGridDetailsData(response) {
  //   let detailSummaryData = [];
  //   if (response.data.length > 0) {
  //     ///calculate the totalpromistopay and RemittancePaid
  //     let totalAdjudicated = 0;
  //     let totalPaid = 0;
  //     for (let i = 0; i < response.data.length; i++) {
  //       totalAdjudicated += response.data[i].promiseToPay;
  //       totalPaid += response.data[i].remittancePaid;
  //     }

  //     detailSummaryData.push({
  //       Payer: response.config.params.payer,
  //       ClaimCount: response.data.length,
  //       Adjudicated: totalAdjudicated,
  //       Paid: totalPaid,
  //       Outstanding: totalAdjudicated - totalPaid,
  //     });

  //     setDetailError({});
  //   } else {
  //     setDetailError({
  //       status: 201,
  //       Message: "No data found for given params",
  //     });
  //   }

  //   let detailsDataWithCleanedDates = response.data.map((t) => {
  //     return {
  //       ...t,
  //       dispensedDate: IsNullOrWhitespace(t.dispensedDate)
  //         ? ""
  //         : new Date(Date.parse(t.dispensedDate)),
  //       transactionDate: IsNullOrWhitespace(t.transactionDate)
  //         ? ""
  //         : new Date(Date.parse(t.transactionDate)),
  //     };
  //   });

  //   setDetailsGridData(detailsDataWithCleanedDates);
  //   setDetailsSummaryData(detailSummaryData);

  //   setShowModal(true);
  // }

  function CreateGridColumn(column) {
    let columnWidth = DefaultColumnWidth();
    if (column.accessor !== "PBID") {
      let filterType = "text";
      if (column.type === "money") {
        filterType = "numeric";
      } else if (column.type === "bool") {
        filterType = "boolean";
      }

      if (column.type === "money") {
        return (
          <GridColumn
            key={column.accessor}
            filter={filterType}
            field={column.accessor}
            title={column.title}
            width={columnWidth}
            cells={{
              data: MainGridMoneyCell,
              footerCell: AggregateMainGridMoneyFooterCell,
            }}
            footerCell={AggregateMainGridMoneyFooterCell}
          />
        );
      } else {
        return (
          <GridColumn
            key={column.accessor + "Column"}
            filter={filterType}
            field={column.accessor}
            title={column.title}
            width={columnWidth}
          />
        );
      }
    }
  }

  return (
    <React.Fragment>
      <LoadingSpinner
        isDataLoading={isLoading}
        controlsName={"agingAsOfAging"}
      />
      {!hasTPARAccess ? (
        <div>
          <br />
          <div className="bordered-control">
            <h4
              style={{
                color: "#034681",
                textAlign: "center",
              }}
            >
              No access
            </h4>
            <hr />
            <div
              style={{
                textAlign: "center",
              }}
            >
              You’ve clicked on a solution that you are currently not subscribed
              to, therefore content on this page is unavailable. If you are
              interested in enrolling in Third Party Accounts Receivable to
              enable this feature, please contact Net-Rx at sales@net-rx.com.
            </div>
          </div>
        </div>
      ) : (
        <div className="feature-tab" id="As-Of-Aging">
          <ErrorHandler
            error={error}
            onClose={() => {
              setError({});
            }}
          />
          <Accordion defaultExpanded label="Search & Filter">
            <AsOfAgingForm handleFormSubmit={handleFormSubmit} />
          </Accordion>

          <div ref={_container}>
            <Row>
              <Col className="tight-grid multi-line-filter">
                <SharedMainGrid
                  data={mainGridData}
                  aggregateColumnSettings={mainGridMoneyColumnAggregateSettings}
                  sendAggregatesParentCallback={sendAggregatesToParent}
                  exportFileNamePrefix={"AsOfAging"}
                >
                  {mainGridColumns.length > 0
                    ? mainGridColumns.map((singleColumn) =>
                        CreateGridColumn(singleColumn)
                      )
                    : null}
                </SharedMainGrid>
              </Col>
            </Row>
          </div>

          <DetailsModal
            title="Payer Summary"
            show={showModal}
            handleClose={() => {
              setShowModal(false),
                setDetailsGridDataState({}),
                setDetailsGridData([]),
                _container.current.focus();
            }}
          >
            <ErrorHandler
              error={detailError}
              onClose={() => {
                setDetailError({});
              }}
            />
            <div>
              <Row>
                <Col className="tight-grid multi-line-filter">
                  <Grid
                    style={{
                      maxHeight: "10em",
                    }}
                    data={detailsSummaryData}
                  >
                    {detailsSummaryData[0] && detailsSummaryData[0].Payer ? (
                      <GridColumn
                        key={"Payer"}
                        field={"Payer"}
                        title={"Payer"}
                        width={DefaultColumnWidth()}
                      />
                    ) : null}
                    {/* <GridColumn
                  key={"Payer"}
                  field={"Payer"}
                  title={"Payer"}
                  width={DefaultColumnWidth()}
                /> */}
                    <GridColumn
                      key={"ClaimCount"}
                      field={"ClaimCount"}
                      title={"Claim Count"}
                      width={DefaultColumnWidth()}
                    />
                    <GridColumn
                      key={"Adjudicated"}
                      field={"Adjudicated"}
                      title={"Adjudicated"}
                      width={DefaultColumnWidth()}
                      cells={{ data: MoneyCell }}
                    />
                    <GridColumn
                      key={"Paid"}
                      field={"Paid"}
                      title={"Paid"}
                      width={DefaultColumnWidth()}
                      cells={{ data: MoneyCell }}
                    />
                    <GridColumn
                      key={"Outstanding"}
                      field={"Outstanding"}
                      title={"Outstanding"}
                      width={DefaultColumnWidth()}
                      cells={{ data: MoneyCell }}
                    />
                  </Grid>
                </Col>
              </Row>
            </div>
            <br />
            <h2>Aging Detail</h2>
            <h4>{detailsGridTitle}</h4>
            <ZIndexContext.Provider value={10003}>
              <Row>
                <Col className="tight-grid multi-line-filter">
                  <SharedMainSelectableServerGrid
                    styleOverride={{
                      maxHeight: "30em",
                    }}
                    aggregateColumnSettings={null}
                    sendAggregatesParentCallback={() => {}}
                    sendSelectablesParentCallback={() => {}}
                    exportFileNamePrefix={"AsOfAgingAgingDetails"}
                    apiGetEndpoint={"api/AgingDetails/GetByAsOfAgingServerGrid"}
                    apiSubmittedFormData={detailsGridParameters}
                    setServerGridParentData={setServerGridData} //NOTE: Set thiis if you want the server grid data to be stored in a state variable which we can use later
                  >
                    <GridColumn
                      key={"ncpdp"}
                      field={"ncpdp"}
                      title={"NCPDP"}
                      width={DefaultBooleanOrShortWidthColumnWidth()}
                    />
                    <GridColumn
                      key={"rxNumber"}
                      field={"rxNumber"}
                      title={"Rx #"}
                      filter={"text"}
                      width={DefaultColumnWidth()}
                    />
                    <GridColumn
                      key={"dispensedDate"}
                      field={"dispensedDate"}
                      title={"Dispensed Date"}
                      filter={"date"}
                      format="{0:d}"
                      width={DefaultDateColumnWidth()}
                    />
                    <GridColumn
                      key={"transactionDate"}
                      field={"transactionDate"}
                      title={"Transaction Date"}
                      filter={"date"}
                      format="{0:d}"
                      width={DefaultDateColumnWidth()}
                    />
                    <GridColumn
                      key={"bin"}
                      field={"bin"}
                      title={"BIN"}
                      width={DefaultColumnWidth()}
                    />
                    <GridColumn
                      key={"pcn"}
                      field={"pcn"}
                      title={"PCN"}
                      width={DefaultColumnWidth()}
                    />
                    <GridColumn
                      key={"claimNumber"}
                      field={"claimNumber"}
                      title={"Claim #"}
                      width={DefaultColumnWidth()}
                    />
                    <GridColumn
                      key={"cardHolderId"}
                      field={"cardHolderId"}
                      title={"Cardholder ID"}
                      width={DefaultColumnWidth()}
                    />
                    <GridColumn
                      key={"adjudicatedCoPay"}
                      field={"adjudicatedCoPay"}
                      title={"Copay"}
                      filter={"numeric"}
                      width={DefaultColumnWidth()}
                      cells={{
                        data: MoneyCell,
                        footerCell: AggregateMoneyDetailFooterCell,
                      }}
                      footerCell={AggregateMoneyDetailFooterCell}
                    />
                    <GridColumn
                      key={"promiseToPay"}
                      field={"promiseToPay"}
                      title={"Promise to Pay"}
                      filter={"numeric"}
                      width={DefaultColumnWidth()}
                      cells={{
                        data: MoneyCell,
                        footerCell: AggregateMoneyDetailFooterCell,
                      }}
                      footerCell={AggregateMoneyDetailFooterCell}
                    />
                    <GridColumn
                      key={"remittancePaid"}
                      field={"remittancePaid"}
                      title={"Paid"}
                      filter={"numeric"}
                      width={DefaultColumnWidth()}
                      cells={{
                        data: MoneyCell,
                        footerCell: AggregateMoneyDetailFooterCell,
                      }}
                      footerCell={AggregateMoneyDetailFooterCell}
                    />
                    <GridColumn
                      key={"outstanding"}
                      field={"outstanding"}
                      title={"Outstanding"}
                      filter={"numeric"}
                      width={DefaultColumnWidth()}
                      cells={{
                        data: MoneyCell,
                        footerCell: AggregateMoneyDetailFooterCell,
                      }}
                      footerCell={AggregateMoneyDetailFooterCell}
                    />
                  </SharedMainSelectableServerGrid>

                  {/* <Grid
                      ref={_detailsGrid}
                      style={{
                        maxHeight: "30em",
                      }}
                      data={detailsGridResultState}
                      onDataStateChange={onDetailsGridDataStateChange}
                      sortable={true}
                      {...detailsGridDataState}
                      filterable={true}
                      pageable={true}
                    >
                      <GridColumn
                        key={"ncpdp"}
                        field={"ncpdp"}
                        title={"NCPDP"}
                        width={DefaultBooleanOrShortWidthColumnWidth()}
                      />
                      <GridColumn
                        key={"rxNumber"}
                        field={"rxNumber"}
                        title={"Rx #"}
                        filter={"text"}
                        width={DefaultColumnWidth()}
                      />
                      <GridColumn
                        key={"dispensedDate"}
                        field={"dispensedDate"}
                        title={"Dispensed Date"}
                        filter={"date"}
                        format="{0:d}"
                        width={DefaultDateColumnWidth()}
                      />
                      <GridColumn
                        key={"transactionDate"}
                        field={"transactionDate"}
                        title={"Transaction Date"}
                        filter={"date"}
                        format="{0:d}"
                        width={DefaultDateColumnWidth()}
                      />
                      <GridColumn
                        key={"bin"}
                        field={"bin"}
                        title={"BIN"}
                        width={DefaultColumnWidth()}
                      />
                      <GridColumn
                        key={"pcn"}
                        field={"pcn"}
                        title={"PCN"}
                        width={DefaultColumnWidth()}
                      />
                      <GridColumn
                        key={"claimNumber"}
                        field={"claimNumber"}
                        title={"Claim #"}
                        width={DefaultColumnWidth()}
                      />
                      <GridColumn
                        key={"cardHolderId"}
                        field={"cardHolderId"}
                        title={"Cardholder ID"}
                        width={DefaultColumnWidth()}
                      />
                      <GridColumn
                        key={"adjudicatedCoPay"}
                        field={"adjudicatedCoPay"}
                        title={"Copay"}
                        filter={"numeric"}
                        width={DefaultColumnWidth()}
                        cells={{
                          data: MoneyCell,
                          footerCell: AggregateMoneyDetailFooterCell,
                        }}
                        footerCell={AggregateMoneyDetailFooterCell}
                      />
                      <GridColumn
                        key={"promiseToPay"}
                        field={"promiseToPay"}
                        title={"Promise to Pay"}
                        filter={"numeric"}
                        width={DefaultColumnWidth()}
                        cells={{
                          data: MoneyCell,
                          footerCell: AggregateMoneyDetailFooterCell,
                        }}
                        footerCell={AggregateMoneyDetailFooterCell}
                      />
                      <GridColumn
                        key={"remittancePaid"}
                        field={"remittancePaid"}
                        title={"Paid"}
                        filter={"numeric"}
                        width={DefaultColumnWidth()}
                        cells={{
                          data: MoneyCell,
                          footerCell: AggregateMoneyDetailFooterCell,
                        }}
                        footerCell={AggregateMoneyDetailFooterCell}
                      />
                      <GridColumn
                        key={"outstanding"}
                        field={"outstanding"}
                        title={"Outstanding"}
                        filter={"numeric"}
                        width={DefaultColumnWidth()}
                        cells={{
                          data: MoneyCell,
                          footerCell: AggregateMoneyDetailFooterCell,
                        }}
                        footerCell={AggregateMoneyDetailFooterCell}
                      />
                    </Grid> */}
                </Col>
              </Row>
            </ZIndexContext.Provider>
          </DetailsModal>
        </div>
      )}
    </React.Fragment>
  );
}
